<template>
  <div>
    <BalanceGymComp v-bind:mobile="mobile"/>
  </div>
</template>

<script>
import BalanceGymComp from "@/components/desktop/Activities/BalanceGymComp";

  export default {
    name: 'BalanceGym',
    props: ['mobile'],
    components: {
      BalanceGymComp,
    },
  }
</script>
